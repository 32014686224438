



















































































































































































































































import { defineComponent } from "@vue/composition-api";
import { mapStores } from "pinia";
import { mapActions } from "vuex";
import type {
	GroepslessenResultaat,
	GuessedReservering,
	LesUitgebreid,
} from "@/lib/backend/coach.api";
import { DEFAULT_TRACKING } from "@/lib/constants/misc";
import { useGebruikerStore } from "@/pinia/gebruiker";
import { useGroepslesStore } from "@/pinia/groepsles";
import { arrayUniqueByKey } from "@/utils/arrayUniqueByKey";
import {
	getDateFromDatum,
	getDateFromDatumAndTijd,
	getDayMonth,
	getDayMonthYear,
	getWeekDay,
} from "@/utils/date";

type Data = {
	loading: boolean;
	showAllGroepslessen: boolean;
	suggestions: LesUitgebreid[];
};

export default defineComponent({
	name: "PfgGebruikerGroepslessen",
	props: {},
	data(): Data {
		return {
			loading: false,
			showAllGroepslessen: false,
			suggestions: [],
		};
	},
	computed: {
		...mapStores(useGebruikerStore, useGroepslesStore),
		groepslesSuggestions(): GroepslessenResultaat["suggestions"] {
			return this.gebruikerStore.groepslessen.suggestions ?? [];
		},
		lastLessons(): GroepslessenResultaat["lastLessons"] {
			return this.gebruikerStore.groepslessen.lastLessons ?? [];
		},
		lastLesson(): GroepslessenResultaat["lastLesson"] {
			return this.gebruikerStore.groepslessen.lastLesson;
		},
		lastLessonDate(): GroepslessenResultaat["lastLessonDate"] {
			return this.gebruikerStore.groepslessen.lastLessonDate;
		},
		isTracking(): boolean {
			return this.gebruikerStore?.tracking?.grouplessons ?? DEFAULT_TRACKING;
		},
	},
	watch: {
		$route: "check",
	},
	async created() {
		await this.check();
	},
	methods: {
		...mapActions("overlay", ["openOverlay"]),
		...mapActions("modal", ["openModal"]),
		async check() {
			this.loading = true;

			const { id } = this.$route.params;

			const numberId = Number(id);

			if (isNaN(numberId)) return;

			const currentId = this.gebruikerStore.id;

			if (currentId !== numberId) this.gebruikerStore.set(numberId);

			await this.getRequiredInfo();
			await this.gebruikerStore.getTracking();

			this.loading = false;
		},
		async getRequiredInfo() {
			await this.gebruikerStore.getGroepslessen();
			await this.gebruikerStore.getReserveringen();
			await this.groepslesStore.getAankomendeGroepslessen();
			this.suggestions = arrayUniqueByKey(
				await this.groepslesStore.getGroepslessen(),
				"groepsles_id",
			);
		},
		nextLessonTime(name: string): string {
			if (!this.groepslesStore.aankomend.length) return "";

			const NOW = new Date();

			const les: { datum: string; tijd: string } = this.groepslesStore.aankomend
				.filter((groepsles: LesUitgebreid): boolean => groepsles.groepsles_naam === name)
				.find(
					(groepsles: LesUitgebreid): boolean =>
						getDateFromDatumAndTijd(groepsles.datum, groepsles.tijd) > NOW,
				) ?? { datum: "", tijd: "" };

			return `${les.datum} ${les.tijd}`;
		},
		openAfmeldenModal(reservering: GuessedReservering) {
			this.openModal({
				name: "groepsles-afmelden",
				data: { les: reservering, lidId: this.gebruikerStore.id },
				callback: () => this.gebruikerStore.getReserveringen(),
			});
		},
		async changeTracking(active: boolean) {
			await this.gebruikerStore.updateTracking({
				value: active,
				type: "groepslessen",
			});

			if (active === true) await this.getRequiredInfo();
			else await this.gebruikerStore.getGroepslessen();
		},
		getDateFromDatum,
		getWeekDay,
		getDayMonth,
		getDayMonthYear,
	},
});
